import ReactPlayer from "react-player";
import Nav from "./Nav";

function FullStory({ userData }) {
  return (
    <>
      <Nav userData={userData} />

      <div className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 py-24 grid grid-cols-12">
        <div className="col-span-12 md:col-span-8 md:col-start-3">
          <div className="px-6">
            <h1 className="text-4xl mb-2">The Story</h1>
            <p className="text-lg text-gray-700 mb-8">
              A look behind the Bitcoin Birds collection.
            </p>
          </div>

          {/* <img
            src="/abraham.jpg"
            alt="Abraham holding a duck"
            className="rounded-xl w-full object-cover mb-2"
          /> */}

          <div className="relative mb-10" style={{ paddingTop: "56.25%" }}>
            <ReactPlayer
              controls
              volume={0.5}
              width="100%"
              height="100%"
              className="absolute top-0 left-0"
              url="https://www.youtube.com/watch?v=7IoIrr-LSDw"
            />
          </div>

          <div className="md:px-4 md:mx-2">
            <p className="text-lg text-gray-600 mb-6">
              Bitcoin Birds was created by 12-year-old Abraham Finlay as an extension of his love for birds and Bitcoin.
            </p>
            <p className="text-lg text-gray-600 mb-6">
              Several years ago Abraham took an interest in local wildlife and birds, and started learning everything he could about them. When he found out about a local wildlife rescue group, he immediately showed interest in joining as a critter carrier. Abraham has volunteered to go out and catch injured birds for the organization for the past few years. The work is often difficult as injured wildlife can be difficult to catch at times and this has taught Abraham much persistence.
            </p>
            <p className="text-lg text-gray-600 mb-6">
              He ultimately wants his digital birds to help physical birds. Abraham plans to donate a portion of the proceeds he receives from Bitcoin Birds towards helping wildlife.
            </p>
            <p className="text-lg text-gray-600 mb-12">
              The release of the Bitcoin Bird collection is only the first step. More details to come but these efforts will likely include support for injured bird rescue, habitat restoration, education and more. We would love to hear more suggestions from the Bitcoin Bird community!
            </p>
          </div>

          <div className="flex flex-wrap px-4">
            <a
              href="https://www.stacksart.com/collections/Bitcoin-birds/market"
              className="flex-1 whitespace-nowrap transition-all ease-in duration-75 px-6 py-3 mx-2 mb-4 rounded-lg font-medium bg-green-600 text-white shadow-sm hover:shadow-md inline-flex items-center justify-center"
            >
              Visit marketplace
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
            </a>

            <a
              href="/#collection"
              className="flex-1 whitespace-nowrap transition-all ease-in duration-75 px-6 py-3 mx-2 mb-4 rounded-lg font-medium border shadow-sm hover:shadow-md inline-flex items-center justify-center"
            >
              Explore the collection
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default FullStory;
