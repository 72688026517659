import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import { userSession } from "./auth";
import collectionData from "./collection-rarity.json";

import MyNFTs from "./components/MyNFTs";
import NotFound from "./components/NotFound";
import FullStory from "./components/FullStory";
import Landing from "./components/landing/Landing";

const BitcoinBirdAssetId = "SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.bitcoin-birds::bitcoin-birds";

class App extends Component {
  state = {
    nftOffset: 0,
    loading: true,
    userData: null,
  }

  componentDidMount() {
    if (userSession.isSignInPending()) {
      userSession.handlePendingSignIn().then((userData) => {
        window.history.replaceState({}, document.title, "/")
        this.setState({
          loading: false,
          userData: userData
        });
      });
    } else if (userSession.isUserSignedIn()) {
      const userData = userSession.loadUserData();
      const mainnetAddress = userData.profile.stxAddress.mainnet;

      this.setState({
        userData,
        loading: false
      });

      this.loadNFTs(mainnetAddress);
    } else {
      this.setState({ loading: false });
    }
  }

  loadNFTs = async (address, currentNFTs = []) => {
    const { nftOffset } = this.state;
    const url = `https://stacks-node-api.mainnet.stacks.co/extended/v1/address/${address}/nft_events?limit=50&offset=${nftOffset}`;

    try {
      const { data } = await axios.get(url);
      const nfts = [];

      // Sort Bitcoin Birds
      data.nft_events.forEach(nft => {
        const { asset_identifier } = nft;

        if (asset_identifier.includes(BitcoinBirdAssetId)) {
          // Only add if not already in array
          if (!nfts.length) {
            nfts.push(nft.value.repr);
          } else {
            // Check if already in array
            const index = nfts.findIndex(repr => {
              return repr === nft.value.repr;
            });

            if (index === -1) {
              nfts.push(nft.value.repr);
            }
          }
        }
      });

      const birds = nfts.map(id => {
        const formatId = parseInt(id.replace("u", ""));
        const index = collectionData.findIndex(bird => bird.Id === formatId);

        return collectionData[index];
      }).sort((a, b) => {
        return a["Rarity Rank"] - b["Rarity Rank"];
      });

      // Recursive loading
      if (data.total > nftOffset) {
        this.setState(state => ({
          nftOffset: state.nftOffset + 50
        }));
        this.loadNFTs(address, [...currentNFTs, ...birds]);
      } else {
        this.setState(state => ({
          userData: {
            ...state.userData,
            nfts: [...currentNFTs, ...birds]
          },
          nftOffset: state.nftOffset + 50
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      loading,
      userData
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className="antialiased">
        <Routes>
          <Route path="/" element={<Landing userData={userData} />} />
          <Route path="/my-birds" element={<MyNFTs userData={userData} />} />
          <Route path="/story" element={<FullStory userData={userData} />} />
          <Route path="*" element={<NotFound userData={userData} />} />
        </Routes>
      </div>
    );
  }
}

export default App;
