import React, { Component } from "react";
import Nav from "../Nav";
import Header from "./Header";
import Story from "./Story";
import Roadmap from "./Roadmap";
import Birds from "./Birds";

class Landing extends Component {
  render() {
    const { userData } = this.props;

    return (
      <>
        <Nav userData={userData} />

        <Header />
        <Story />
        <Roadmap />
        <Birds />
      </>
    )
  }
}

export default Landing;
