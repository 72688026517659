import { Link } from "react-router-dom";

function Story() {
  return (
    <>
      <div className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 mb-24">
        <div className="grid grid-cols-12 bg-gray-100 rounded-2xl px-4 py-8 lg:py-16 gap-6 lg:gap-12">
          <div className="mb-4 md:mb-0 col-span-12 md:col-span-6 lg:col-span-5 lg:col-start-2">
            <img
              src="/abraham.jpg"
              alt="Abraham holding a duck"
              className="rounded-xl w-full md:h-72 object-cover border"
            />
          </div>

          <div className="col-span-12 md:col-span-6 lg:col-span-5 flex flex-col justify-center">
            <h2 className="text-3xl mb-2">Read The Story</h2>

            <p className="text-lg text-gray-600 mb-4">
              Bitcoin Birds was created by 12-year-old Abraham Finlay as an extension of his love for birds and Bitcoin.
            </p>

            <Link
              to="/story"
              className="whitespace-nowrap transition-all self-start ease-in duration-75 px-6 py-3 rounded-lg shadow-sm hover:shadow-md text-white font-medium bg-green-600 inline-flex items-center justify-center"
            >
              Read the full story
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Story;
