import React, { Component } from "react";
import Logo from "../logo.png";
import { Link } from "react-router-dom";
import { FaTwitter, FaDiscord } from "react-icons/fa";
import { authenticate, signOut } from "../auth";

class Nav extends Component {
  state = {
    showNav: false
  }

  toggleNav = () => {
    this.setState({
      showNav: !this.state.showNav
    });
  }

  render() {
    const { showNav } = this.state;
    const { userData } = this.props;
    const navClass = showNav ? "lg:opacity-100" : "opacity-0 -top-96";

    return (
      <div className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 relative">
        <div className="py-6 grid grid-cols-1 lg:grid-cols-12 justify-between">
          <div className="col-span-4 flex items-center justify-between">
            <Link to="/" className="text-2xl font-bold flex items-center tracking-tighter">
              <img
                src={Logo}
                alt="logo"
                className="h-12 w-auto rounded-xl pixelated mr-3"
              />

              Bitcoin Birds
            </Link>

            <button onClick={this.toggleNav} className="lg:hidden flex justify-center items-center relative w-12 h-12 rounded-full border hover:border-gray-300 hover:shadow-sm transition-all duration-100">
              {/* Change icons to x when nav is open */}
              <svg xmlns="http://www.w3.org/2000/svg" className={`${showNav ? "opacity-100" : "opacity-0"} h-6 w-6 transition-all duration-200 absolute`} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" /></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className={`${!showNav ? "opacity-100" : "opacity-0"} h-6 w-6 transition-all duration-200 absolute`} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 6h16M4 12h16M4 18h16" /></svg>
            </button>
          </div>

          <div className="col-span-4 hidden lg:flex justify-center items-center space-x-10">
            {userData ? (
              <Link to="/my-birds" className="flex items-center font-medium tracking-tight hover:text-gray-600 duration-100">
                My Birds
                <span className="flex items-center justify-center text-white text-sm w-6 h-6 rounded-full bg-green-600 ml-2">
                  {userData.nfts !== undefined && userData.nfts.length}
                </span>
              </Link>
            ) : (
              <button onClick={authenticate} className="font-medium tracking-tight hover:text-gray-600 duration-100">
                My Birds
              </button>
            )}
            <Link to="/story" className="flex items-center font-medium tracking-tight hover:text-gray-600 duration-100">
              Story
            </Link>
          </div>

          <div className="col-span-4 hidden lg:flex justify-end">
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://twitter.com/bitcoin_birds"
              className="flex items-center justify-center font-bold rounded-full w-12 h-12 mr-2 border tracking-tight text-gray-600 hover:bg-blue-500 hover:text-white hover:border-none hover:shadow-sm transition-all duration-100"
            >
              <FaTwitter />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://discord.gg/99gvrVJvcT"
              className="flex items-center justify-center font-bold rounded-full w-12 h-12 mr-2 border tracking-tight text-gray-600 hover:bg-indigo-600 hover:text-white hover:border-none hover:shadow-sm transition-all duration-100"
            >
              <FaDiscord />
            </a>

            {userData ?
              <button onClick={signOut} className="flex items-center justify-center font-bold rounded-full px-8 h-12 border tracking-tight hover:border-gray-300 hover:shadow-sm transition-all duration-100">
                <div className="w-3 h-3 mr-2 bg-green-600 rounded-full"></div>
                Sign Out
              </button>
            :
              <button onClick={authenticate} className="font-bold rounded-full px-8 h-12 border tracking-tight hover:border-gray-300 hover:shadow-sm transition-all duration-100">
                Connect Wallet
              </button>
            }
          </div>
        </div>

        <div className={`${navClass} transition-all duration-200 w-full absolute left-0 px-3 lg:hidden`}>
          <div className="flex flex-col bg-white border rounded-xl shadow-lg px-3 py-6">
            <div className="hidden sm:block">
              {userData ? (
                <Link
                  to="/my-birds"
                  onClick={this.toggleNav}
                  className="flex items-center justify-between font-bold tracking-tight focus:bg-gray-200 duration-100 px-3 py-2 rounded-md"
                >
                  My Birds
                  <span className="flex items-center justify-center text-white text-sm w-6 h-6 rounded-full bg-green-600 ml-2">
                    {userData.nfts !== undefined && userData.nfts.length}
                  </span>
                </Link>
              ) : (
                <button
                  onClick={() => {
                    authenticate();
                    this.toggleNav();
                  }}
                  className="w-full font-bold tracking-tight text-left cursor-pointer focus:bg-gray-100 duration-100 px-3 py-2 rounded-md"
                >
                  My Birds
                </button>
              )}
            </div>
            <Link
              to="/story"
              onClick={this.toggleNav}
              className="flex items-center justify-between font-bold tracking-tight focus:bg-gray-200 duration-100 px-3 py-2 rounded-md"
            >
              Story
            </Link>
            <a
              target="_blank"
              onClick={this.toggleNav}
              rel="noopener noreferrer nofollow"
              href="https://twitter.com/bitcoin_birds"
              className="font-bold tracking-tight focus:bg-gray-200 duration-100 px-3 py-2 rounded-md"
            >
              Twitter
            </a>
            <a
              target="_blank"
              onClick={this.toggleNav}
              rel="noopener noreferrer nofollow"
              href="https://discord.gg/99gvrVJvcT"
              className="font-bold tracking-tight focus:bg-gray-200 duration-100 px-3 py-2 rounded-md"
            >
              Discord
            </a>

            <div className="flex flex-col items-center py-2 px-3">
              <hr className="w-full my-6" />

              {userData ?
                <button
                  onClick={() => {
                    signOut();
                    this.toggleNav();
                  }}
                  className="flex items-center justify-center font-bold rounded-full my-2 px-8 h-12 border tracking-tight hover:border-gray-300 hover:shadow-sm transition-all duration-100"
                >
                  <div className="w-3 h-3 mr-2 bg-green-600 rounded-full"></div>
                  Sign Out
                </button>
              :
                <button
                  onClick={() => {
                    authenticate();
                    this.toggleNav();
                  }}
                  className="font-bold rounded-full my-2 px-8 h-12 border tracking-tight hover:border-gray-300 hover:shadow-sm transition-all duration-100"
                >
                  Connect Wallet
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Nav;
