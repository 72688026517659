function Roadmap() {
  return (
    <>
      <div className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 mb-24">
        <h2 className="text-3xl mb-3 sm:mb-0">Project Roadmap</h2>

        <div className="grid grid-cols-12 gap-3 sm:gap-6">
          <div className="col-span-12"></div>

          <div className="col-span-12 md:col-span-6 lg:col-span-3 border-2 rounded-2xl p-4 shadow-sm flex flex-col">
            <div
              style={{ background: "#8B9C5A" }}
              className="w-full rounded-2xl overflow-hidden flex items-center justify-center mb-6"
            >
              <img
                alt="Bird"
                src="/bitcoin-birds/bird-265.png"
                className="pixelated h-40 mx-auto"
              />
            </div>

            <p className="text-green-600 font-medium tracking-tighter text-sm">
              Complete
            </p>
            <h4 className="text-xl mb-2 flex justify-between">Original Mint</h4>

            <p className="text-gray-700 mb-12">
              The original Bitcoin Birds mint. We also announced that 10% of
              royalties will be put towards bird and wildlife conservation
              efforts.
            </p>

            <p className="font-medium text-center flex mt-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-green-600 h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Complete
            </p>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-3 border-2 rounded-2xl p-4 shadow-sm flex flex-col">
            <div
              style={{ background: "#FF8E42" }}
              className="w-full rounded-2xl overflow-hidden flex items-center justify-center mb-4"
            >
              <img
                alt="Bird"
                src="/bitcoin-birds/bird-313.png"
                className="pixelated h-40 mx-auto"
              />
            </div>

            <p className="text-green-600 font-medium tracking-tighter text-sm">
              Complete
            </p>
            <h4 className="text-xl mb-2">First Wildlife Project</h4>

            <p className="text-gray-700 mb-12">
              We're working at our local natural area planting native trees and
              shrubs. These plants will provide future shade, animal nesting and
              foraging, and erosion control.
            </p>

            <p className="font-medium text-center flex mt-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-green-600 h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Complete
            </p>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-3 border-2 rounded-2xl p-4 shadow-sm flex flex-col">
            <div
              style={{ background: "#5546FF" }}
              className="w-full rounded-2xl overflow-hidden mb-4"
            >
              <img
                alt="block gallery"
                src="/block-gallery.svg"
                className="pixelated w-full h-40"
              />
            </div>

            <p className="text-green-600 font-medium tracking-tighter text-sm">
              Complete
            </p>
            <h4 className="text-xl mb-2">Block Gallery</h4>

            <p className="text-gray-700 mb-12">
              We just launched Block Gallery! Our mission with Block Gallery is
              to provide tools for purposeful projects, organizations, and
              artists to help them flourish through the innovative use of NFTs.{" "}
              <a
                href="https://www.blockgallery.com"
                className="text-blue-500 hover:underline"
              >
                blockgallery.com
              </a>
            </p>

            <p className="font-medium text-center flex mt-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-green-600 h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Complete
            </p>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-3 border-2 rounded-2xl p-4 shadow-sm flex flex-col">
            <div
              style={{ background: "#3F51B5" }}
              className="w-full rounded-2xl overflow-hidden mb-4"
            >
              <img
                alt="turtles"
                src="/turtles.png"
                className="pixelated w-full h-40 object-cover"
              />
            </div>

            <p className="text-gray-600 font-medium tracking-tighter text-sm">
              Coming April, 2022
            </p>
            <h4 className="text-xl mb-2">Turtles NFT</h4>

            <p className="text-gray-700 mb-12">
              We're excited to welcome the newest members of the Bitcoin Birds
              family, the Turtles! The pre-mint, will
              begin at 10am PST, April 12th and run for ~48 hours. The public
              mint will be shortly after.{" "}
              <a
                href="https://www.blockgallery.com/collection/turtles"
                className="text-blue-500 hover:underline"
              >
                View the collection!
              </a>
            </p>

            <p className="font-medium text-center flex mt-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-600 h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Coming April, 2022
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roadmap;
