import React, { Component } from "react";

const backgroundColors = {
  Lightblue: "#07C9DE",
  Purple: "#A939FF",
  Pink: "#EC407A",
  Brown: "#FFE0B2",
  Orange: "#FF8E42",
  Yellow: "#F2D113",
  Darkblue: "#3F51B5",
  Grey: "#617D8B",
  Darkgreen: "#8A9C5A"
};

const colorContrast = hex => {
  // If a leading # is provided, remove it
  if (hex.slice(0, 1) === "#") {
    hex = hex.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hex.length === 3) {
    hex = hex.split("").map(hex => hex + hex).join("");
  }

  // Convert to RGB value
  const r = parseInt(hex.substr(0,2),16);
  const g = parseInt(hex.substr(2,2),16);
  const b = parseInt(hex.substr(4,2),16);

  // Get YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Check contrast
  return (yiq >= 128) ? "" : "text-white ";
};

class Bird extends Component {
  render() {
    const { bird } = this.props;

    return (
      <>
        <div
          style={{
            background: backgroundColors[bird.Background]
          }}
          className="w-full h-60 rounded-t-2xl overflow-hidden"
        >
          <img
            alt="Bird"
            src={`/bitcoin-birds/bird-${bird.Id}.png`}
            className="pixelated h-60 mx-auto"
          />
        </div>

        <div className="p-4 flex flex-col rounded-b-2xl h-60 border-l border-r border-b">
          <div>
            <p className="font-bold text-sm text-indigo-600">#{bird["Rarity Rank"]} Rarity Rank</p>
            <h4 className="font-bold text-xl">
              Bitcoin Bird #{bird.Id}
            </h4>
            <p className="font-medium text-sm text-gray-500 mb-3">{bird["Bird Type"]} Bird</p>
          </div>

          <div className="mt-auto">
            <h6 className="font-medium">Attributes</h6>

            <div className="flex flex-wrap">
              {bird.Attributes.length ?
                bird.Attributes.map((attribute, index) =>
                  <button
                    key={index}
                    className="px-3 py-1 rounded-full mt-2 mr-2"
                    style={{
                      backgroundColor: backgroundColors[bird.Background]
                    }}
                  >
                    <p className={colorContrast(backgroundColors[bird.Background]) + "font-medium text-sm"}>
                      {attribute}
                    </p>
                  </button>
                )
              :
                <p className="text-sm text-gray-500 font-medium">This bird doesn't have any attributes.</p>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Bird;
