import { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import collectionData from "../../collection-rarity.json";
import attributeData from "../../attribute-rarity.json";
import Bird from "../cards/Bird";

class Birds extends Component {
  state = {
    birds: [],
    offset: 0,
    search: "",
    typing: false,
    loading: true,
    sort: "rarity",
    typingTimeout: 0,
    showAttributes: false
  }

  handleSearchChange = ({ target }) => {
    const search = target.value;
    const { typingTimeout } = this.state;

    this.setState({ search });

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      typing: false,
      loading: true,
      showAttributes: false,
      typingTimeout: setTimeout(this.searchBirds, search.trim().length ? 500 : 0)
    });
  }

  componentDidMount() {
    this.loadBirds();
  }

  searchBirds = textSearch => {
    const search = textSearch || this.state.search;

    this.setState({
      offset: 0,
      birds: [],
      loading: true,
      showAttributes: false,
      search: textSearch || this.state.search,
    });

    if (search.match(/\d+/)) {
      this.setState({
        loading: false,
        birds: collectionData.filter(bird => {
          return bird.Id === parseInt(search.match(/\d+/));
        })
      });
    } else if (search.trim().length > 0) {
      const formattedSearch = search.toLowerCase().trim();

      // Search algorithm
      const birds = collectionData.filter(bird => {
        let include = false;
        const type = bird["Bird Type"].toLowerCase();

        if (!formattedSearch.includes("bird")) {
          bird.Attributes.forEach(attribute => {
            if (attribute.toLowerCase().includes(formattedSearch)) {
              include = true;
            }
          });
        }

        if (type.includes(formattedSearch) || type.includes(formattedSearch.replace("bird", "").trim())) {
          include = true;
        }

        return include;
      });

      this.setState({
        birds,
        loading: false,
      });
    } else {
      this.setState({
        offset: 0,
        birds: [],
        loading: false,
      });

      this.loadBirds();
    }
  }

  sortBirds = (birdA, birdB) => {
    const { sort } = this.state;

    // Sort birds based on rarity
    if (sort === "rarity") {
      return birdB["Rarity Score"] - birdA["Rarity Score"];
    }
  }

  loadBirds = () => {
    const {
      offset,
      search,
    } = this.state;

    if (search.length) {
      return;
    }

    this.setState({ loading: true });

    const data = collectionData.sort(this.sortBirds).slice(offset, offset + 15);

    this.setState(state => ({
      loading: false,
      offset: offset + 15,
      birds: [...state.birds, ...data],
    }));
  }

  refreshBirds = () => {
    this.setState({ loading: true });

    const data = collectionData.sort(this.sortBirds).slice(0, 15);

    this.setState({
      offset: 15,
      search: "",
      loading: false,
      birds: [...data],
    });
  }

  clearSearch = () => {
    this.refreshBirds();
  }

  toggleShowAttributes = () => {
    this.setState(state => ({
      showAttributes: !state.showAttributes
    }));
  }

  render() {
    const {
      birds,
      search,
      loading,
      showAttributes,
    } = this.state;
    const resultLength = loading ? "#" : search.trim().length ? birds.length : collectionData.length;
    const attributeList = attributeData
    .reverse()
    .filter(({ Attribute }) =>!Attribute.includes("Background"))
    .filter(({ Attribute }) => !Attribute.includes("Attribute Count"));

    return (
      <>
        <div id="collection" className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 grid grid-cols-12 sm:gap-6 mb-6">
          <div className="col-span-12">
            <h1 className="text-3xl mb-6">The Collection</h1>

            <div className="flex flex-wrap items-end justify-between mb-6">
              <div className="w-full sm:w-96">
                <label htmlFor="search" className="font-medium text-gray-700">Search the Birds...</label>

                <div className="relative rounded-lg border border-gray-300 w-full my-2 sm:mb-0">
                  {search.trim().length > 0 &&
                    <button
                      onClick={this.clearSearch}
                      className="absolute h-full px-3 right-0 text-gray-500 rounded-lg"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                    </button>
                  }

                  <input
                    type="text"
                    id="search"
                    name="search"
                    value={search}
                    onChange={this.handleSearchChange}
                    className="block rounded-lg px-3 py-2 w-full outline-none focus:bg-gray-50"
                    placeholder="Search... #199, Fedora, Crown"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={this.toggleShowAttributes}
                  className="flex h-full items-center border border-gray-300 rounded-lg font-medium text-sm hover:bg-gray-50 px-4 py-2"
                >
                  Attributes

                  {showAttributes ?
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" /></svg>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                  }
                </button>
              </div>
            </div>

            <div className="flex justify-between">
              <p className="font-medium leading-3">
                {resultLength} Result{resultLength !== 1 ? "s" : ""}
              </p>

              <a
                className="font-medium leading-3 underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://docs.google.com/spreadsheets/d/1WZ2JWuRMw94WosIaMYgKIRU_YBJ-e3voas7C2xlNrfI/edit?usp=sharing"
              >
                View Rarity Sheet
              </a>
            </div>

            {showAttributes &&
              <div className="flex flex-wrap mt-4">
                {attributeList.map(({ Attribute }, index) =>
                  <button
                    key={index}
                    onClick={() => {
                      this.toggleShowAttributes();
                      this.searchBirds(Attribute);
                    }}
                    className="border rounded-full hover:bg-gray-50 px-3 py-1 mt-2 mr-2"
                  >
                    <p className="font-medium text-sm">
                      {Attribute}
                    </p>
                  </button>
                )}
              </div>
            }
          </div>
        </div>

        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => this.loadBirds()}
          hasMore={birds.length < 400}
          loader={loading ? <div key={0}></div> : null}
          className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 grid grid-cols-12 sm:gap-6 lg:gap-6 mb-6 birds"
        >
          {birds.map((bird, index) =>
            <div
              key={index}
              className="col-span-12 mb-3 shadow-sm sm:mb-0 sm:col-span-6 lg:col-span-4 rounded-2xl"
            >
              <Bird bird={bird} />
            </div>
          )}

          {!birds.length &&
            <div className="col-span-12">
              <p className="text-xl">
                We didn't find any birds that matched that search.
              </p>
            </div>
          }
        </InfiniteScroll>
      </>
    )
  }
}

export default Birds;
