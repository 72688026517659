import Nav from "./Nav";
import Bird from "./cards/Bird";
import NotFound from "./NotFound";

const MyNFTs = ({ userData }) => {
  if (!userData) {
    return <NotFound userData={userData} />;
  }

  const { nfts } = userData;

  return (
    <>
      <Nav userData={userData} />

      <div className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 py-12">
        {nfts && nfts.length > 1 && <h1 className="text-4xl mb-6">My Birds</h1>}

        <div className="grid grid-cols-12 gap-4">
          {/* Show four placeholders */}
          {!nfts && (
            Array.from(Array(4)).map((_, index) => (
              <div key={index} className="col-span-12 mb-3 h-96 w-auto bg-gray-200 sm:col-span-6 lg:col-span-4 rounded-2xl" />
            ))
          )}

          {nfts && nfts.length > 0 &&
            nfts.map((bird, index) => {
              return (
                <div
                  key={index}
                  className="col-span-12 shadow-sm sm:col-span-6 lg:col-span-4 rounded-2xl"
                >
                  <Bird bird={bird} />
                </div>
              );
            })
          }

          {nfts && nfts.length === 0 && (
            <div className="col-span-12 mt-6 lg:col-start-4 lg:col-span-6 lg:mt-24">
              <h3 className="text-2xl font-bold mb-1">
                No Birds found
              </h3>
              <p className="mb-6">
                We didn't find any birds at your address. However, you can still buy a bird on the marketplace!
              </p>

              <a
                href="https://www.stacksart.com/collections/bitcoin-birds/market"
                className="whitespace-nowrap transition-all ease-in duration-75 px-6 py-3 rounded-lg font-medium bg-green-600 text-white shadow-sm hover:shadow-md inline-flex items-center justify-center"
              >
                Visit the marketplace
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MyNFTs;
