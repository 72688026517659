import { Person } from "@stacks/profile";
import { AppConfig, UserSession, showConnect } from "@stacks/connect";

const appConfig = new AppConfig(["store_write", "publish_data"]);

export const userSession = new UserSession({ appConfig });

export function authenticate() {
  showConnect({
    appDetails: {
      name: "Bitcoin Birds",
      icon: "https://www.bitcoinbirds.com/android-chrome-512x512.png",
    },
    redirectTo: "/",
    onFinish: () => window.location.reload(),
    userSession: userSession,
  });
}

export function signOut() {
  return userSession.signUserOut("/");
}

export function getUserData() {
  return userSession.loadUserData();
}

export function getPerson() {
  return new Person(getUserData().profile);
}
