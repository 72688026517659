import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";

function NotFound({ userData }) {
  return (
    <>
      <Nav userData={userData} />

      <div className="max-w-3xl mx-auto px-3 sm:px-6 2xl:px-0 py-12">
        <img
          alt="Bitcoin Bird"
          src="/not-found.gif"
          className="mb-6"
        />

        <h3 className="text-2xl font-bold mb-1">
          404: Page not found
        </h3>
        {window.location.href.includes("/my-birds") ? (
          <p className="mb-6">
            Uh oh! It looks like you aren't signed in. Please connect your wallet to view your Birds.
          </p>
        ) : (
          <p className="mb-6">
            Uh oh! It looks like the page you're looking for doesn't exist.
          </p>
        )}

        <Link
          to="/"
          className="whitespace-nowrap transition-all ease-in duration-75 px-6 py-3 rounded-lg font-medium bg-green-600 text-white shadow-sm hover:shadow-md inline-flex items-center justify-center"
        >
          Go Home
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
        </Link>
      </div>
    </>
  );
}

export default NotFound;
