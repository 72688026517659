import BirdsSm from "../../bitcoin-birds-sm.png";

function Header() {
  return (
    <>
      <div className="max-w-screen-xl mx-auto px-3 sm:px-6 2xl:px-0 grid grid-cols-12 gap-6 md:gap-12 mt-12 mb-24 lg:mt-24">
        <div className="order-2 col-span-12 lg:order-1 md:col-span-10 lg:col-span-6">
          <h1 className="text-3xl md:text-4xl font-medium mb-2 lg:mb-6">
            Four hundred unique NFTs on Stacks, secured by Bitcoin.
          </h1>

          <p className="text-lg text-gray-600 mb-6">
            The Bitcoin Birds collection is 400 uniquely handmade pixel art birds. No two are exactly the same, and each one of them can be officially owned by a single person on the Stacks blockchain. Originally, they could be randomly minted for 20 STX by anyone with a Stacks wallet, but all 400 quickly sold out.
          </p>

          <a
            href="https://www.stacksart.com/collections/bitcoin-birds/market"
            className="whitespace-nowrap transition-all ease-in duration-75 px-10 py-3 rounded-lg font-medium bg-green-600 text-white hover:shadow-md inline-flex items-center justify-center"
          >
            Visit marketplace
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
          </a>
        </div>

        <div className="order-1 lg:block lg:order-2 col-span-12 lg:col-span-6">
          <img
            src={BirdsSm}
            style={{
              width: "100%"
            }}
            alt="Bitcoin Birds"
            className="rounded-2xl w-full pixelated"
          />
        </div>
      </div>
    </>
  )
}

export default Header;
